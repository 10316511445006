import { defineComponent, toRefs, reactive, computed, watch, defineAsyncComponent } from 'vue';
import { ElMessageBox } from "element-plus";
import API from "@/api/studentCard/index";
import { useMessage } from '@/hooks/web/useMessage';
export default defineComponent({
  name: "StudentDistribution",
  components: {
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue')),
    Eldialog: defineAsyncComponent(() => import('@/components/Eldialog/index.vue'))
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    imeiList: {
      type: Array,
      default: []
    }
  },
  emits: ['handleSuccess', 'update:visible'],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      title: '设备分配',
      list: [],
      mdtids: "",
      holdid: null,
      orgName: '',
      schoolId: null
    });
    watch(() => props.visible, val => {
      refData.list = props.imeiList.length ? props.imeiList : [];
      refData.mdtids = refData.list.join(',');
      refData.holdid = null;
      refData.orgName = '';
    });
    // 初始化表单
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    // 结构树回调
    const handleClick = data => {
      if (data.type == 'school') {
        refData.holdid = data.parentId;
        refData.schoolId = data.id;
      } else {
        refData.holdid = data.id;
        refData.schoolId = null;
      }
      refData.orgName = data.name;
    };
    //确定按钮
    const handleConfirm = async () => {
      try {
        refData.mdtids = refData.mdtids.replace(/\r\n/g, ',');
        refData.mdtids = refData.mdtids.replace(/\s/g, ',');
        refData.list = refData.mdtids.split(",");
        if (!refData.list.length) throw {
          msg: "请添加IMEI号"
        };
        if (!refData.holdid) throw {
          msg: "请选择组织"
        };
        ElMessageBox.confirm(`是否确认将当前“${refData.list.length}”个设备分配到“${refData.orgName}”${!refData.schoolId ? '组织' : '单位'}`, '提示', {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          const params = {
            holdid: refData.holdid,
            schoolId: refData.schoolId,
            mdtids: refData.list.join(',')
          };
          const {
            code,
            msg,
            data
          } = await API.transferStatus(params);
          code == 0 && (emit('update:visible', false), emit('handleSuccess'));
          message[code == 0 ? 'success' : 'warning'](code == 0 ? '分配成功' : JSON.stringify(data));
        }).catch(() => {
          // ElMessage.info("已取消退出");
        });
      } catch ({
        msg
      }) {
        message.warning(msg);
      }
    };
    return {
      ...toRefs(refData),
      show,
      handleClick,
      handleConfirm
    };
  }
});